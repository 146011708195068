@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #005555; */
  user-select: none;
}

/* Add this CSS to your styles */
.drawer {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: -250px;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.drawer .logout-container {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

.drawer .logout-btn {
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  background: #005555;
  color: #fff;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

.animate-bounce {
  animation: bounce 2s infinite;
}

/* Example CSS for dropdown styling */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle::after {
  content: " ▼";
  font-size: 1em;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 10px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-menu li:hover {
  background-color: #ddd;
}

.drawer a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 10px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.image-container {
  height: 640px;
  /* Set your desired fixed height here */
  align-items: center;
}

.image-property {
  width: 100%;
  height: 82%;
  object-fit: fill;
  border-radius: 0.5rem;
  /* This applies the same rounded corners as before */
}

.slick-dots-custom * {
  width: 60px !important;
  height: 40px !important;
}

.drawer a.active {
  background: #005555;
  color: #fff;
  margin-left: 5px;
  margin-right: 5px;
}

.slick-thumb {
  height: 100px;
  width: 100px;
}

.drawer a:hover {
  background: #457c7c;
  margin-left: 5px;
  margin-right: 5px;
  color: #fff;
}

.drawer .close-btn {
  position: absolute;
  top: 0;
  left: -55px;
  border-radius: 50px;
  color: #005555;
  font-size: 60px;
  margin-left: 50px;
}

.drawer.open {
  right: 0;
  z-index: 999999;
}

@media (min-width: 769px) {
  .site-mobile-menu {
    display: none;
  }
}

/* custom-toastr.css */
.toast-success {
  background-color: #28a745 !important;
  /* Green */
}

.toast-info {
  background-color: #17a2b8 !important;
  /* Blue */
}

.toast-warning {
  background-color: #ffc107 !important;
  /* Yellow */
}

.toast-error {
  background-color: #dc3545 !important;
  /* Red */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.field-group-OTP {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.field-group-OTP div:first-child input {
  flex: 0 0 15%;
  height: 50px !important;
  padding: 10px;
  width: 50px !important;
  max-width: 50px;
  box-sizing: border-box;
  text-align: center !important;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #747474;
  margin: 0px 5px;
  border: none;
  outline: 0px;
}

.field-group-OTP .otp-input {
  border: 1px solid #000;
  /* Black border, change as needed */
  border-radius: 4px;
  /* Optional: add border radius for rounded corners */
}

.field-group-OTP div:first-child input::placeholder {
  color: black;
}

.field-group-OTP div:first-child input:focus-visible {
  outline: 0px;
}

.slow-appear-text {
  opacity: 0;
  transform: translateY(20px);
  animation: slowAppear 3s forwards;
}

@keyframes slowAppear {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.css-el0wq6 {
  margin: 0;
  letter-spacing: 0.01em;
  font-size: 1.125rem;
  line-height: 2.125rem;
  font-family: "Calibre", Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 400;
  margin-top: 24px;
  font-family: "Calibre", Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  color: #fff;
}

.doc-preview-container {
  margin-top: 20px;
  width: 40%;
}

.doc-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.doc-input-fix {
  color: #007bff;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #ddd;
}

.tab-button {
  height: 42px;
  width: 120px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: #555;
  transition: border-bottom 0.3s ease;
}

.active-tab {
  /* border-bottom: 2px solid #005555; */
  background: #005555;
  color: #fff;
  border-radius: 8px 8px 0px 0px;
}

.tab-button-solo {
  height: 42px;
  width: 160px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: #555;
  transition: border-bottom 0.3s ease;
}

.active-tab-border {
  border-bottom: 2px solid #005555;
  color: #005555;
  font-weight: bold;
}

.btn-skelton {
  overflow: hidden;
  background: #005555 !important;
}

.btn-subscribe {
  overflow: hidden;
  background: #FA8072 !important;
}

.btn-skelton::after,.btn-subscribe::after {
  background: linear-gradient(-35deg, #ffffff00, #fff, #ffffff00);
  content: "";
  height: 155px;
  left: -75px;
  opacity: .5;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  width: 30px;
  z-index: 200;
  animation: moveDiagonal 2s infinite cubic-bezier(0.19, 1, 0.22, 1);
}

@keyframes moveDiagonal {
  0% {
    left: -75px;
  }
  100% {
    left: 120%;
  }
}


.btn-profile {
  overflow: hidden;
  border: 0px solid #fff;
  animation: glow .7s infinite;
  transition: 0.7s;
}

@keyframes glow {
  0% {
    outline: 1px solid #005555ac;
  }

  25% {
    outline: 2px solid #005555de;
  }

  50% {
    outline: 2px solid #005555;
  }

  75% {
    outline: 2px solid #0055559c;
  }

  100% {
    outline: 2px solid #0055552c;
  }
}


.btn-email-profile {
  overflow: hidden;
  border: 1px solid #fff;
  animation: glown .7s infinite;
  transition: 0.7s;
}

@keyframes glown {
  0% {
    outline: 1px solid #ff6347;;
  }

  25% {
    outline: 2px solid  #ff6347d8;;
  }

  50% {
    outline: 2px solid  #ff634797;
  }

  75% {
    outline: 2px solid  #ff63476d;
  }

  100% {
    outline: 2px solid  #ff634735;
  }
}
